import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import "./index.scss"
const Agreement = () => {
  let List = []
  let Terms = {}
  let info = {}
  const data = useStaticQuery(graphql`
    {
      mdx(fileAbsolutePath: { regex: "/(agreement)/" }) {
        exports {
          Agreement {
            description
            title
          }
          List {
            type
          }
        }
        frontmatter {
          parEula2
          parEula1
          lastUpdate
          subTitleEula
          titleEula
          paraEula
        }
      }
    }
  `)
  List = data.mdx.exports.List
  Terms = data.mdx.exports.Agreement
  info = data.mdx.frontmatter
  return (
    <div id="Agreement" className="Agreement">
      <div className=" sect Title">
        <h1>{info?.titleEula}</h1>
        <p className="lastUpdate">{info?.lastUpdate}</p>
      </div>
      <div className="description">
        <p className="subTitle">{info?.subTitleEula}</p>
        <div>
          {List?.map((item, index) => {
            return (
              <ul className="listStyle" key={index}>
                <li className="list">{item.type}</li>
              </ul>
            )
          })}
        </div>
        <p className="par">{info?.parEula1}</p>
        <p
          className="par1"
          dangerouslySetInnerHTML={{ __html: info?.parEula2 }}
        ></p>
        <p
          className="par"
          dangerouslySetInnerHTML={{ __html: info?.paraEula }}
        ></p>
      </div>
      {Terms?.map((elt, index) => {
        return (
          <div className=" sect" key={index}>
            <h2 className="termsTitle">{elt.title}</h2>
            <p
              className="par"
              dangerouslySetInnerHTML={{ __html: elt.description }}
            ></p>
          </div>
        )
      })}
    </div>
  )
}

export default Agreement
