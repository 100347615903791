import React from "react"
import { Helmet } from "react-helmet"
import Seo from "../seo"
import Agreement from "./agreement"
const Eula = () => {
  return (
    <div className="container">
      <Seo
        title="Eula"
        description="MAXapps presents license agreement for these three add-ons products for use with ibm maximo for asset management"
      />
      <Helmet prepend>
        <meta property="og:title" content="MAXapps: Eula" />
        <meta
          property="og:description"
          content="MAXapps presents license agreement for these three add-ons products for use with ibm maximo for asset management."
        />
        <meta
          property="og:image"
          content="https://maxapps-maximo.com/og/eula.jpg"
        />
        <meta property="og:url" content="https://maxapps-maximo.com/eula" />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@maxapps" />
        <meta name="twitter:title" content="MAXapps: Eula" />
        <meta
          name="twitter:description"
          content="MAXapps presents license agreement for these three add-ons products for use with ibm maximo for asset management."
        />
        <meta
          name="twitter:image"
          content="https://maxapps-maximo.com/og/eula.jpg"
        />
      </Helmet>
      <Agreement />
    </div>
  )
}

export default Eula
