import React from "react"
import Eula from "../components/eula"
import Layout from "../components/layout/t1/layout"

const EULAPage = () => {
  return (
    <Layout>
      <Eula />
    </Layout>
  )
}

export default EULAPage
